import React from "react";
import "./CallButton.css";

const CallButton = ({tel}) => (
  <a className="CallButton" href={`tel:${tel.replace(/\s/g,'')}`}>
    <span className="CallButton-friendlyLabel">Ring mig</span>
    <span className="CallButton-number">{tel}</span>
  </a>
);

export default CallButton;
