import React, { Component } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import "./NavLink.css";
import NavLinkChild from "./NavLinkChild";
import Arrow from "./Arrow";

class NavLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showChild: false
    };
  }

  componentDidMount() {
    this.setState({ showChild: window.location.href.includes(this.props.to) && this.props.to !== '/' });
  }

  toggleChild() {
    this.setState({ showChild: !this.state.showChild });
  }

  render() {
    const { to, childItems, className, children } = this.props;
    const { showChild } = this.state;

    const LinkClass = cx("NavLink", className);

    const ChildClass = cx({
      "Header-childOpen": showChild
    });

    const ChildToggleClass = cx('Header-navItem-childToggle', {
      "Header-navItem-childToggleOpen": showChild
    });

    return (
      <div>
        <div className="NavLink-row">
          <Link className={LinkClass} to={to} activeClassName="NavLink-active">
            {children}
          </Link>
          {childItems &&
            <button type="button" className={ChildToggleClass} onClick={this.toggleChild.bind(this)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" ariahidden="true" role="img"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" fill="#000000"></path></svg>
            </button>
          }
        </div>
        {childItems &&
          <ul className={ChildClass}>
            {childItems && childItems.map((childItem, z) => {
              return (
                <li className="Header-navItem" key={z}>
                  <NavLinkChild to={childItem.path}>
                    <span>{`${childItem.title}`}</span>
                  </NavLinkChild>
                </li>
              );
            })}
          </ul>
        }
      </div>
    );
  }
}

export default NavLink;
