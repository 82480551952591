import React from "react";
import rehypeReact from "rehype-react";
import ReadMoreLink from "./ReadMoreLink";

// const renderAst = new rehypeReact({
//   createElement: (component, props = {}, children = []) => {
//     if (component === "div") {
//       if (Object.keys(props).length === 0) {
//         props.className = "Markdown";
//       }
//     }
//     return React.createElement(component, props, children);
//   },
//   components: {
//     "read-more": ReadMoreLink
//   }
// }).Compiler;

const renderAst = tree => {
  const reply = new rehypeReact({
    createElement: React.createElement,
    components: {
      "read-more": ReadMoreLink
    }
  }).Compiler(tree);
  if (reply.type === "div" && !reply.props.className) {
    return <>{reply.props.children}</>; // remove div if first child without className
  }
  return reply;
};

const Markdown = props => {
  return renderAst(props.children);
};

export default Markdown;
