import { graphql } from "gatsby";

export const getSettings = settings => {
  let returnSettings = {};
  settings.edges.forEach(({ node }) => {
    let section = {
      html: node.html,
      htmlAst: node.htmlAst
    };
    let { frontmatter } = node;
    for (const setting in frontmatter) {
      if (
        frontmatter.hasOwnProperty(setting) &&
        (frontmatter[setting] || frontmatter[setting] === 0)
      ) {
        section[setting] = frontmatter[setting];
      }
    }
    returnSettings[node.frontmatter.name] = section;
  });
  return returnSettings;
};

export const settingsFragment = graphql`
  fragment settingsFragment on MarkdownRemark {
    frontmatter {
      copyright
      defaultDescription
      name
      siteTitle
      type
      tel
      email
      items {
        title
        path
        children {
          title
          path
        }
      }
    }
    html
    htmlAst
  }
`;
