import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import Arrow from "./Arrow";
import "./ReadMoreLink.css";

const ReadMoreLink = props => {
  const LinkClass = cx("ReadMoreLink", props.class);
  return (
    <span className={LinkClass}>
      <Link
        to={props.to}
        className="ReadMoreLink-anchor"
        activeClassName="ReadMoreLink-anchorActive"
      >
        <span className="ReadMoreLink-anchorText">{props.children}</span>
        <Arrow />
      </Link>
    </span>
  );
};

export default ReadMoreLink;
