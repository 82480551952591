import React from "react";

import Markdown from "./Markdown";
import MailLink from "./MailLink"
import PhoneLink from "./PhoneLink"

import logoSvg from "../images/backlund_logo_white.svg";
import "./Footer.css";

const Footer = ({ settings }) => {
  return (
    <footer className="Footer">
      <div className="Footer-container">
        <img src={logoSvg} className="Footer-logo" alt="Backlund Juroidik" />
        <PhoneLink tel={settings.site.tel}/> | <MailLink email={settings.site.email}/>
        <div className="Footer-content">
          <Markdown>{settings.footer.htmlAst}</Markdown>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
