import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { getSettings } from "../../lib/Settings";
import Header from "../Header";
import Footer from "../Footer";
import "./Layout.css";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutSettingsQuery {
        settings: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "settings" } } }
        ) {
          edges {
            node {
              ...settingsFragment
            }
          }
        }
      }
    `}
    render={data => {
      const settings = getSettings(data.settings);
      return (
        <div className="Layout">
          <Helmet
            title={settings.siteTitle}
            meta={[
              { name: "description", content: settings.site.defaultDescription }
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header settings={settings} />
          <main>{children}</main>
          <Footer settings={settings} />
        </div>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
