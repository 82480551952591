import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import "./NavLinkChild.css";

const NavLinkChild = ({ to, className, children }) => {
  const LinkClass = cx("NavLinkChild", className);
  return (
    <Link className={LinkClass} to={to} activeClassName="NavLink-active">
      {children}
    </Link>
  );
};

export default NavLinkChild;
