import React, { Component } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import "./Header.css";
import monogram from "../images/backlund_logo_symbol_small_black.svg";
import logotext from "../images/backlund_logo_text_black.svg";
import logo from "../images/backlund_logo_black.svg";
import NavLink from "./NavLink";
import Arrow from "./Arrow";
import Markdown from "./Markdown";
import MailLink from "./MailLink";
import CallButton from "./CallButton";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false
    };
  }

  toggleNav() {
    this.setState({ showNav: !this.state.showNav });
  }

  render() {
    const { site, menu } = this.props.settings;
    const { showNav } = this.state;

    const className = cx("Header", {
      "Header-navOpen": showNav
    });

    return (
      <header className={className}>
        <div className="Header-logoContainer">
          <Link className="Header-logo" to="/">
            <img
              className="Header-monogram"
              src={monogram}
              alt="Backlund Juridik monogram"
            />
            <img
              className="Header-logoText"
              src={logotext}
              alt="Backlund Juridik"
            />
            <img
              className="Header-logoBig"
              src={logo}
              alt="Backlund Juridik logo"
            />
          </Link>
          <h1 className="Header-title">{site.siteTitle}</h1>
        </div>
        <button
          className="Header-navButton"
          onClick={this.toggleNav.bind(this)}
        >
          <span className="Header-navButtonLine" />
          <span className="Header-navButtonLine" />
          <span className="Header-navButtonText">Toggle menu</span>
        </button>
        <div className="Header-navContainer">
          <ol className="Header-nav">
            {menu.items.map((item, i) => {
              return (
                <li className="Header-navItem" key={i}>
                  <NavLink to={item.path} childItems={item.children}>
                    <span>{`0${i + 1}. ${item.title}`}</span>
                  </NavLink>
                </li>
              );
            })}
            <li className="Header-navItem Header-navItemContact Header-mail" key="mailLink">
              <MailLink email={site.email}></MailLink>
            </li>
            <li className="Header-navItem Header-navItemContact Header-phone" key="phonelink">
              <CallButton tel={site.tel}></CallButton>
            </li>
          </ol>
          <div className="Header-menuExtras">
            <Markdown>{menu.htmlAst}</Markdown>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
