import React from "react";

import "./PhoneLink.css";

const PhoneLink = ({tel}) => {
  return(
  <a className="PhoneLink" href={`tel:${tel.replace(/\s/g,'')}`}>
    <span className="PhoneLink-number">{tel}</span>
  </a>
)};

export default PhoneLink;
