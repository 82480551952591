import React from "react";
import "./MailLink.css";

const MailLink = ({email}) => {
  return(
  <a className="MailLink" href={`mailto:${email}`}>
    <span className="MailLink-mail">{email}</span>
  </a>
)};

export default MailLink;
